@import "src/global";

.dropdown {
  display: flex;
  position: relative;
  padding: 15px;
}

.dropdown:hover,
.dropdown.active {
  background-color: $secondary-light;
}

.dropdown .dropdown-inner {
  width: 200px;
  //position: absolute;
  //top: 35px;
  //left: 15px;
  background-color: #ffffff;
  color: #000000 !important;
  margin: 0;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  //z-index: 1;
}

.dropdown .dropdown-inner .dropdown-inner-header-action-section {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.dropdown .dropdown-inner .dropdown-inner-header-action-section .dropdown-inner-header-action-section-left {
  display: flex;
  flex: 1;
}

.dropdown .dropdown-inner .dropdown-inner-header-action-section .dropdown-inner-header-action-section-right {
  display: flex;
  justify-content: flex-end;

  svg {
    margin: 0 2px;
    cursor: pointer;
    padding: 3px;

    &:hover,
    &.active {
      background-color: #c8c8c8;
      border-radius: 25px;
      padding: 3px;
    }
  }
}

.dropdown .dropdown-inner .filter-layout-title {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}


.dropdown .dropdown-inner .dropdown-inner-section {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: gray;
    width: 15px;
  }
}

.dropdown .dropdown-inner .dropdown-inner-section .field-rows-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 5px;
}

.dropdown .dropdown-inner .dropdown-inner-section .loading-section {
  display: flex;
  width: 100%;
  min-height: 130px;
  align-items: center;
  justify-content: center;

  .loading-icon {
    font-size: 25px;
    color: #000000;
  }
}


//.dropdown .dropdown-inner .dropdown-inner-section select,
//.dropdown .dropdown-inner .dropdown-inner-section input:not(.dropdown .dropdown-inner .dropdown-inner-section input[type = "checkbox"], .dropdown .dropdown-inner .dropdown-inner-section input[type = "radio"]) {
//  padding: 5px;
//  margin-bottom: 5px;
//}

//.dropdown .dropdown-inner .dropdown-inner-section select:disabled,
//.dropdown .dropdown-inner .dropdown-inner-section input:disabled {
//  color: rgba(16, 16, 16, 0.3);
//  background-color: rgba(239, 239, 239, 0.3);
//  border-color: rgba(118, 118, 118, 0.3);
//  cursor: no-drop;
//}

//.dropdown .dropdown-inner .dropdown-inner-section select:focus,
//.dropdown .dropdown-inner .dropdown-inner-section input:focus:not(.dropdown .dropdown-inner .dropdown-inner-section input[type = "checkbox"], .dropdown .dropdown-inner .dropdown-inner-section input[type = "radio"]) {
//  outline: 0;
//}

//.dropdown .dropdown-inner .dropdown-inner-section input[type = "checkbox"],
//.dropdown .dropdown-inner .dropdown-inner-section input[type = "radio"] {
//  margin-left: 0;
//  cursor: pointer;
//}

.dropdown .dropdown-inner .dropdown-inner-section .checkbox-wrapper {
  display: block;
  width: 100%;
  margin-bottom: 7px;
}

.dropdown .dropdown-inner .dropdown-inner-section .checkbox-wrapper > label {
  font-size: 14px;
  font-weight: unset;
  text-transform: capitalize;
  word-break: break-all;
}

.dropdown .dropdown-inner .dropdown-inner-section label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

.dropdown .dropdown-inner .dropdown-inner-checkbox-section {
  display: block;
  width: 100%;
  margin-top: 10px;
}

.dropdown .dropdown-inner .dropdown-inner-checkbox-section > div {
  display: block;
  width: 100%;
  margin-bottom: 7px;
}

//.dropdown .dropdown-inner .dropdown-inner-checkbox-section input[type = "checkbox"] {
//  margin-left: 0;
//  cursor: pointer;
//}

.dropdown .dropdown-inner .dropdown-inner-checkbox-section label {
  cursor: pointer;
}

.dropdown .dropdown-inner .dropdown-inner-action-section {
  margin-top: 15px;
}

.dropdown .dropdown-inner .dropdown-inner-action-section .filter {
  width: 47%;
  padding: 5px;
  margin-right: 5px;
}

.dropdown .dropdown-inner .dropdown-inner-action-section .clear {
  width: 47%;
  padding: 5px;
  margin-left: 5px;
}

.dropdown .dropdown-inner .dropdown-inner-date-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 10px;
}

.dropdown .dropdown-inner .dropdown-inner-date-section label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

//.dropdown .dropdown-inner .dropdown-inner-date-section select {
//  padding: 5px;
//}

.dropdown .dropdown-inner .dropdown-inner-date-section input[type="date"] {
  padding: 5px;
}

.dropdown .dropdown-inner .dropdown-inner-search-section {
  display: flex;
  flex: 1;
  margin-top: 10px;
}

.dropdown .dropdown-inner .dropdown-inner-date-section input[type="text"] {
  padding: 5px;
}

