.export-dropdown {
	display: block;
	width: 200px;
	position: absolute;
	top: 40px;
	left: -170px;
	background-color: #ffffff;
	color: #000000 !important;
	margin: 0;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
	z-index: 10;
	text-align: left;
	.export-dropdown-header {
		display: flex;
		flex: 1;
		flex-direction: row;
		padding: 10px 15px;
		border-bottom: 1px solid gray;
		.export-dropdown-header-left {
			display: flex;
			flex: 1;
			align-items: center;
			.title {
				font-size: 15px;
				padding: 0;
				margin: 0;
			}
		}
		.export-dropdown-header-right {
			display: flex;
			align-items: center;
			.close-icon {
				font-size: 15px;
				cursor: pointer;
			}
		}
	}
	.export-dropdown-content {
		padding: 15px;
		text-align: center;
		p {
			margin: 0;
		}
	}
}
