@import 'node_modules/@fortawesome/fontawesome-svg-core/styles';
@import 'tippy.js/dist/tippy';
@import './global';

html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 14px;
	color: $text-color;
}

body {
	background-color: $body-background-color;
}

*,
*:before,
*:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

a {
	color: $link-color;
}

table {
	width: 100%;
}

.app {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	flex-direction: column;
	min-height: 100vh;
	font-family: $font-family !important;

	.app-header {
		display: flex;
		height: 60px;
		flex-direction: column;
		flex: 1;

		.app-header-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			// background-color: $primary;
			background-color: $grayish-blue;

			.app-brand {
				display: flex;
				align-items: center;
				// justify-content: center;
				height: 60px;
				padding: 0 7px;
				margin: 0;
				// background-color: $primary;
				color: $color-white;
				// border-right: 0.5px solid $color-white;

				.app-brand-logo {
					width: auto;
					height: 100%;
					max-height: 50px;
					margin-left: 10px;
				}

				.app-brand-name {
					font-size: 18px;
					font-weight: bold;
					color: $color-white;
					margin: 0;
					padding: 0;
				}
			}

			.app-content-header {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;
				height: 60px;
				// background-color: $primary;
				padding: 0 15px;

				.app-menu-container {
					display: inline-block;
					cursor: pointer;

					.bar1,
					.bar2,
					.bar3 {
						width: 25px;
						height: 4px;
						background-color: $color-white;
						margin: 5px 0;
						transition: 0.4s;
					}
				}

				.app-menu-container.active {
					.bar1 {
						-webkit-transform: rotate(-45deg) translate(-4.5px, 6px);
						transform: rotate(-45deg) translate(-4.5px, 6px);
					}

					.bar2 {
						opacity: 0;
					}

					.bar3 {
						-webkit-transform: rotate(45deg) translate(-6.5px, -8px);
						transform: rotate(45deg) translate(-6.5px, -8px);
					}
				}

				.user-icon {
					font-size: 25px;
					color: $color-white;
				}

				.user-dropdown {
					position: relative;
					display: inline-block;
				}

				.user-dropdown-content {
					display: none;
					position: absolute;
					background-color: #ffffff;
					min-width: 150px;
					//padding: 12px 16px;
					z-index: 1;
					right: -10px;
					box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
						0 6px 20px 0 rgba(0, 0, 0, 0.19);
					border-radius: 3px;
					z-index: 2;

					.user-detail-section {
						padding: 12px 16px;
						border-bottom: 1px solid gray;

						.user-name {
							font-size: 16px;
							padding: 0;
							margin: 0;
						}

						.user-role {
							font-size: 11px;
							padding: 0;
							margin: 2px 0 0 0;
						}
					}

					.links-section {
						p {
							margin: 0;
							padding: 12px 16px;
						}

						p:hover {
							background-color: #dedede;
						}
					}
				}

				.user-dropdown:hover .user-dropdown-content {
					display: block;
				}
			}
		}

		.app-header-breadcrumb {
			display: flex;
			flex-direction: row;
			background-color: $color-white;
			color: #000;
			padding: 10px 15px;
			box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
			z-index: 1;

			p {
				font-size: 14px;
				color: #363636;
				padding: 0;
				margin: 0;

				b {
					color: #000000;
				}
			}
		}
	}

	.app-content-wrapper {
		display: flex;
		flex: 1;
		flex-wrap: wrap;
		flex-direction: row;
		min-height: 90vh;

		.app-sidebar {
			display: flex;
			flex-direction: column;
			width: 280px;
			background-color: $color-white;
			transition: all 0.2s;

			&.active {
				margin-left: -280px;
			}


			.app-sidebar-header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 15px;
				border-bottom: 0.5px solid rgb(201, 201, 201);

				p {
					font-size: 16px;
					padding: 0;
					margin: 0;
				}

				.close-icon {
					font-size: 20px;
					cursor: pointer;
				}
			}

			ul.app-navbar-list {
				margin: 0;
				padding: 0;
				list-style-type: none;

				li {
					margin: 0;
					overflow-x: hidden;

					&>a {
						text-decoration: none;
					}

					&>a:active,
					a:hover {
						text-decoration: none;
						outline: 0;
					}

					// & > a:hover span {
					// 	// color: $text-color-inverse;

					// 	.menu-icon{
					// 		color: $color-white;
					// 	}
					// }

					&>a.active span {
						// background-color: $secondary !important;
						// color: $text-color-inverse !important;
						background-color: $light-grayish-blue !important;//$grayish-blue !important;
						color: $bright-blue !important;//$primary-dark !important;

						.menu-icon {
							// color: $color-white !important;
							color: $bright-blue !important;//$primary-dark !important;
						}
					}

					&>a span {
						color: $text-color;
						display: block;
						width: 100%;
						font-size: 16px;
						font-weight: 400;
						line-height: 30px;
						padding: 10px 20px;

						.menu-icon {
							margin-right: 10px;
							color: $primary-alt;
						}
					}

					&>a span:hover {
						background-color: rgba(0, 0, 0, 0.04);
					}
				}
			}
		}

		.app-content {
			display: flex;
			flex: 1;
			flex-direction: column;
			width: calc(100% - 280px);
			transition: all 0.3s;

			.app-content-block {
				display: block;
				padding: 15px;
				// overflow-y: auto;
				// max-height: 85vh;

				.app-content-block-inner {
					display: block;
					// background-color: $color-white;
					padding: 10px;
				}
			}
		}
	}
}

.um {
	display: block;

	.code {
		background-color: #eee;
		border-radius: 3px;
		font-family: courier, monospace;
		padding: 6px;
	}
}

.um-top-heading {
	background-color: $primary;
	color: #fafafa;
	padding: 16px;
	margin: 0;
}

.um-main-content {
	display: block;
	padding: 16px;
}

.um-show-hide-filter-section {
	display: block;
	width: 100%;
	margin-bottom: 10px;

	.um-show-hide-filter-button {
		width: 130px;
		margin-right: 10px;
	}
}

.um-button {
	font-size: 12px;
	background-color: $btn-default-background-color;
	color: $btn-default-color;
	border: 1px solid $btn-default-border-color;
	text-decoration: none;
	padding: 7px 20px;
	margin: 0;
	border-radius: $btn-default-border-radius;
	cursor: pointer;

	&:hover {
		outline: 0;
	}

	&:focus {
		outline: 0;
	}

	&:active {
		opacity: 0.8;
	}
}

.um-button.um-button--enter {
	background-color: $btn-enter-background-color;
	color: $btn-enter-color;
	border: 1px solid $btn-enter-border-color;
}

.um-button.um-button--primary {
	background-color: $btn-primary-background-color;
	color: $btn-primary-color;
	border: 1px solid $btn-primary-border-color;
}

.um-button.um-button--danger {
	background-color: $btn-danger-background-color;
	color: $btn-danger-color;
	border: 1px solid $btn-danger-border-color;
}

.um-button.um-button--error {
	background-color: $btn-error-background-color;
	color: $btn-error-color;
	border: 1px solid $btn-error-border-color;
}

.um-button.um-button--info {
	background-color: $btn-information-background-color;
	color: $btn-information-color;
	border: 1px solid $btn-information-border-color;
}

.um-button.um-button--success {
	background-color: $btn-success-background-color;
	color: $btn-success-color;
	border: 1px solid $btn-success-border-color;
}

.um-input:not(.um-input[type='checkbox'], .um-input[type='radio']) {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	border: 1px solid #c2c2c2;
	border-radius: $input-border-radius;
	padding: 5px 8px;
	outline: 0;
}

.um-select {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	border: 1px solid #c2c2c2;
	border-radius: $select-border-radius;
	padding: 5px;
	outline: 0;

	&:invalid {
		color: gray;
	}
}

.um-input[type='checkbox'],
.um-input[type='radio'] {
	border-color: #c2c2c2;
}

.um-input[type='checkbox'],
.um-input[type='radio'] {
	margin-left: 0;
	cursor: pointer;
}

.um-input:disabled,
.um-select:disabled {
	cursor: no-drop;
	color: rgb(170, 170, 170) !important;
	background-color: rgb(238, 238, 235) !important;
	opacity: 1 !important;
}

.um-input-group-wrapper {
	display: inline-block;
	width: auto;

	.um-input-group {
		display: flex;
		align-items: center;

		.um-input-group-prepend {
			display: flex;
			height: 100%;
			background-color: #e6e6e6;
			padding: 6px 15px;
			font-size: 12px;
			border-radius: 2px 0 0 2px !important;
			cursor: default;
		}

		input.um-input {
			display: flex;
			height: 30px !important;
			margin: 0 !important;
			border-right: none !important;
			border-radius: 0 !important;
		}

		select.um-select {
			display: flex;
			height: 30px !important;
			//padding: 5px;
			margin: 0 !important;
			border-radius: 0 2px 2px 0 !important;
			cursor: pointer;
		}
	}
}

.divider {
	display: flex;

	&:before {
		content: '';
		flex: 1;
	}

	&:after {
		content: '';
		flex: 1;
	}
}

.line {
	align-items: center;
	margin: 10px 0;

	&:before {
		height: 1px;
		margin: 0 1em;
	}

	&:after {
		height: 1px;
		margin: 0 1em;
	}
}

.razor {
	&:before {
		box-shadow: 0 0.5px 0 black;
	}

	&:after {
		box-shadow: 0 0.5px 0 black;
	}
}

.full-width {
	width: 100%;
}

.w-50 {
	width: 50px;
}

.w-100 {
	width: 100px;
}

.m-0 {
	margin: 0;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-5 {
	margin-top: 5px;
}

.mr-5 {
	margin-right: 5px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-15 {
	margin-right: 15px;
}

.ml-5 {
	margin-left: 5px;
}

.ml-10 {
	margin-left: 10px;
}

.ml-15 {
	margin-left: 15px;
}

.mt-6 {
	margin-top: 6px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-100 {
	margin-top: 100px;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-5 {
	margin-bottom: 5px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-50 {
	margin-bottom: 50px;
}

.p-0 {
	padding: 0;
}

.um-alert {
	display: flex;
	flex-direction: row;
	padding: 10px 16px;
	font-size: 14px;
	font-weight: 400;
	border: 1px solid transparent;
	border-radius: 4px;
	letter-spacing: 0.01071em;
	opacity: 1;
	-webkit-transition: opacity 500ms linear;
	transition: opacity 500ms linear;

	.um-alert-icon-section {
		display: flex;
		margin-right: 5px;

		.bell-icon {
			margin-top: 3px;
		}
	}

	.um-close-icon {
		font-size: 16px;
		cursor: pointer;

		svg {
			fill: #6d6465
		}
	}

	.um-alert-content-section {
		display: flex;
		flex: 1;
		flex-direction: column;

		ul {
			padding-left: 14px;
		}
	}

	p {
		padding: 0;
		margin: 0 0 0 10px;
	}

	&.success {
		color: #0f5132;
		background-color: #d1e7dd;
		border-color: #badbcc;
	}

	&.warning {
		color: #664d03;
		background-color: #fff3cd;
		border-color: #ffecb5;
	}

	&.error {
		color: #842029;
		background-color: #f8d7da;
		border-color: #f5c2c7;
	}
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.strike-text {
	width: 100%;
	font-size: 14px;
	font-weight: 500;
	border-bottom: 0.5px solid #545454;
	line-height: 3.5px;
	margin: 20px 0 10px 0;

	span {
		background: #fff;
		padding: 0 10px 0 0;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.skeleton {
	.bar {
		background-color: #e7e7e7;
		height: 25px;
		//border-radius: 7px;
		width: 100%;
	}

	&:after {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		content: '';
		display: block;
		width: 100%;
		height: 24px;
		background-image: linear-gradient(100deg,
				rgba(255, 255, 255, 0),
				rgba(255, 255, 255, 0.5) 60%,
				rgba(255, 255, 255, 0) 80%);
		background-size: 200px 24px;
		background-position: -100px 0;
		background-repeat: no-repeat;
		animation: loading 1s infinite;
	}
}

.um-modal {
	flex: 1;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);

	&.hide {
		display: none;
		pointer-events: none;
	}

	&.show {
		display: flex;
		pointer-events: auto;
	}

	.um-modal-content {
		background-color: #fefefe;
		width: 100%;
		border: 1px solid #888;
		border-radius: 10px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		animation-name: animateTop;
		animation-duration: 0.4s;

		.um-modal-header {
			padding: 20px;
			border-bottom: 1px solid #dee2e6;

			.um-modal-header-text {
				font-size: 18px;
				font-weight: bold;
				padding: 0;
				margin: 0;
			}

			.close {
				color: #aaaaaa;
				float: right;
				font-size: 30px;
				font-weight: bold;
				margin-top: -10px;

				&:hover {
					color: #000;
					text-decoration: none;
					cursor: pointer;
				}

				&:focus {
					color: #000;
					text-decoration: none;
					cursor: pointer;
				}
			}
		}

		.um-modal-body {
			padding: 20px;

			label {
				font-size: 14px;
				margin-bottom: 5px;
				display: block;
			}

			.um-input {
				height: 40px;
				padding: 0 10px !important;
				// margin-bottom: 20px;
			}

			.checkbox-wrapper {
				display: flex;
				flex: 1;
				flex-direction: row;
				align-items: center;
			}

			.um-select {
				height: 40px;
				padding: 0 7px !important;
				// margin-bottom: 20px;
			}

			.um-button {
				height: 35px;
			}

			.validation-error {
				font-size: 12.5px;
				color: $error-color;
				padding: 5px 0 0 0;
				margin: 0;
			}
		}
	}
}

.sc-section {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 100vh;
}

.inputPasswordWrapper {
	display: block;
	width: '100%';
	position: relative;
	z-index: 0;

	.password-visibility-icon {
		font-size: 16px;
		position: absolute;
		top: 10px;
		right: 15px;
		cursor: pointer;
		z-index: 5;
	}
}

.list-table {
	max-height: 70vh;
	overflow: auto;

	thead {
		position: sticky;
		z-index: 1;
		top: 0;

		th[name^="label-"] {
			>span {
				display: none;
			}
		}

	}
}

svg.svg-inline--fa.fa-edit, svg.svg-inline--fa.fa-key, svg.svg-inline--fa.fa-trash{
	color: #1976d2 !important;
}