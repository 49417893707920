// dtha
.dtha-section {
  .dtha-aggregate-icon {
    font-size: 14px;
    color: #b9b7b7;
    cursor: pointer;
  }

  .loading-section {
    min-height: 62.5px !important;
  }

  .dtha-aggregate-value {
    text-align: center;
  }

}
