.users-section {
	.users-filter-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 15px;

		.users-filter-left-section {
			display: flex;
			flex: 1;
			flex-direction: row;
			align-items: center;

			.users-filter-role-select,
			.users-filter-group-select,
			.users-filter-search-input {
				width: 100%;
				max-width: 200px;
				margin-right: 10px;
				min-height: 30px;
			}
		}

		.users-filter-right-section {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			width: 200px;
			margin-left: 10px;
		}
	}
}
