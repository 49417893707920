@import "../../../src/global";

div
  table {
    border-spacing: 0;
    border: 1px solid  rgba(0,0,0,0.07);
    border-top: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      &:not(.hasChild) {
        td:first-child{
          padding-left: 27px;
        }
      }
    }
    // tbody tr:hover{
    //   background: rgba(0,0,0,0.05)!important;
    // }
    tbody tr:nth-child(odd) {
      background: $body-background-color;
    }
    tbody tr:nth-child(even) {
      background: $component-background;
    }
    th,
    td {
      // &:first-child{
      //   width: 45px;
      // }
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(0,0,0,0.07);
      border-right: 1px solid rgba(0,0,0,0.07);
      :last-child {
        border-right: 0;
      }
      border: 1px solid 1px solid #f0f0f0;
    }
  }
