.um-modal {
  flex: 1;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  &.hide {
    display: none;
    pointer-events: none;
  }

  &.show {
    display: flex;
    pointer-events: auto;
  }

  .um-modal-content {
    background-color: #fefefe;
    width: 100%;
    border: 1px solid #888;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation-name: animateTop;
    animation-duration: 0.4s;

    .um-modal-header {
      padding: 20px;
      border-bottom: 1px solid #dee2e6;

      .um-modal-header-text {
        font-size: 18px;
        font-weight: bold;
        padding: 0;
        margin: 0;
      }

      .close {
        color: #aaaaaa;
        float: right;
        font-size: 30px;
        font-weight: bold;
        margin-top: -10px;

        &:hover {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }

        &:focus {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    .um-modal-body {
      padding: 20px;

      .item-center{
        display: flex;
        align-items: center;
      }

      .list-table{
        max-height: 50vh;
        overflow: auto;
        margin-bottom: 20px;
        thead{
          position: sticky;
          z-index: 1;
          top: 0;
        }
      }
    }
  }

}
