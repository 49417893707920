@import "src/global";

div.list-table{
    table{
        background-color: $table-background-color;
        thead{
            background-color: $table-header-background-color;//$secondary-light;
            color: $table-header-color;//$primary-dark;
            tr{
                th{
                    font-weight: 500;
                    padding: 15px;
                }
                
            }
        }
        tbody{
            tr{
                &:nth-child(odd){
                    background-color: $table-odd-row-background-color;
                }
                &:nth-child(even){
                    background-color: $table-even-row-background-color;
                }
                td{
                    padding: 15px; 
                }
                td:last-child{
                    text-align: center;
                }
            }
        }
    }
}